
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SmsReminderEditorForm from '@/components/forms/settings/SmsReminderEditorForm.vue';
import ReminderTabs from '@/views/new-design/pages/Settings/Reminders/ReminderTabs.vue';

import { Actions, Getters, Mutations } from '@/store/enums/ReminderEnums';
import toasts from '@/utils/toasts';
import store from '@/store';
import { Reminder } from '@/models/ReminderModel';

const initialValues = {
  send_when: '',
  no_of_days: 1,
} as unknown as Reminder;

export default defineComponent({
  name: 'sms-reminder-editor-page',
  components: {
    ReminderTabs,
    SmsReminderEditorForm,
  },
  data: () => ({
    currentSystemName: '',
    currentReminderDays: '',
    formData: initialValues,
    actionLoading: false,
    pageLoading: false,
    loading: false,
    showForm: false,
    showFormLoading: false,
    formSubmitted: false,
    templateType: '' as any,
    formErrorMessages: [],
    reminderOptions: ['overdue'],
  }),
  mounted() {
    this.loadInit();
  },
  computed: {
    ...mapGetters({
      currentReminder: Getters.GET_REMINDER,
      reminders: Getters.GET_REMINDERS,
      title: Getters.GET_TITLE,
      errors: Getters.GET_ACTION_ERROR,
      placeholders: Getters.GET_PLACEHOLDERS,
      reminderLists: Getters.GET_REMINDER_LIST,
    }),
    placeholderOptions() {
      let placeholderOptions = [] as any;

      if (typeof this.placeholders !== 'undefined') {
        this.placeholders.map((placeholder) => {
          placeholderOptions.push({
            value: placeholder,
            label: placeholder,
          });
        });
      }
      return placeholderOptions;
    },
  },
  methods: {
    ...mapActions({
      fetchReminderByDay: Actions.FETCH_REMINDER_BY_DAY,
      fetchRemindersByName: Actions.FETCH_REMINDERS_BY_NAME,
      fetchPlaceholders: Actions.FETCH_PLACEHOLDERS,
      updateReminder: Actions.UPDATE_REMINDER,
      createReminder: Actions.CREATE_REMINDER,
      deleteReminder: Actions.DELETE_REMINDER,
    }),

    async loadInit() {
      this.loading = true;

      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;

      this.currentSystemName = template as string;
      this.templateType = templateType;

      this.setTitle();

      await this.loadRemindersByName();

      await this.loadPlaceholders();

      this.loading = false;
      this.showForm = false;
      this.showFormLoading = false;
      this.pageLoading = false;
    },

    setTitle() {
      const filteredList = this.reminderLists.filter(
        (reminderList: any | Reminder, key) => {
          return reminderList?.system_name === this.currentSystemName;
        }
      );

      store.commit(Mutations.SET_TITLE, filteredList[0]?.name);
    },

    async loadPlaceholders() {
      const { template } = this.$route.query;

      await this.fetchPlaceholders(template);
    },

    async loadRemindersByName() {
      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;

      const payload = {
        type: templateType,
        systemName: template,
      };

      await this.fetchRemindersByName(payload)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          store.commit(Mutations.SET_REMINDERS, {});
        });
    },

    handleNewReminder() {
      this.showFormLoading = true;
      this.showForm = true;
      store.commit(Mutations.SET_REMINDER, {});

      this.formData = initialValues;

      setTimeout(() => {
        this.showForm = true;
        this.showFormLoading = false;
      }, 1000);
    },

    async handleSelectReminder(reminder) {
      this.showFormLoading = true;
      this.showForm = false;

      this.currentReminderDays = reminder.no_of_days;

      await this.loadCurrentReminder();

      setTimeout(() => {
        this.showFormLoading = false;
        this.showForm = true;
      }, 1000);
    },

    async loadReminderTemplate() {
      this.setTitle();
    },

    async loadCurrentReminder() {
      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;

      this.pageLoading = true;
      this.showForm = false;

      const payload = {
        type: templateType,
        systemName: template,
        days: this.currentReminderDays,
      };

      await this.fetchReminderByDay(payload)
        .then(() => {
          this.pageLoading = false;
          this.showForm = true;
        })
        .catch(() => {
          store.commit(Mutations.SET_REMINDER, {});
          this.pageLoading = false;
        });
    },

    handeCreateReminder(values) {
      if (this.formSubmitted) return;

      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;

      this.formSubmitted = true;

      let formData = {
        ...values,
        ...{
          type: templateType,
          system_name: template,
        },
      };

      const payload = {
        type: templateType,
        data: formData,
      };

      this.createReminder(payload)
        .then(() => {
          this.formSubmitted = false;
          this.pageLoading = false;

          toasts.success('Reminder Successfully Saved', () => {
            this.currentReminder = {} as Reminder;
            this.loadRemindersByName();
          });
        })
        .catch(() => {
          this.currentReminder = {} as Reminder;
          this.formSubmitted = false;
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },

    handleUpdateReminder(values) {
      if (this.formSubmitted) return;

      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;

      this.formSubmitted = true;

      let formData = {
        ...values,
        ...{
          system_name: template,
        },
      };

      const payload = {
        type: templateType,
        id: values.id,
        data: formData,
      };

      this.updateReminder(payload)
        .then(() => {
          this.formSubmitted = false;
          toasts.success('Reminder Successfully Modified');
        })
        .catch(() => {
          this.currentReminder = {} as Reminder;
          this.formSubmitted = false;
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },

    handleDeleteReminder(values) {
      if (this.actionLoading) return;

      const templateType = this.$route.meta.template;

      this.actionLoading = true;

      const payload = {
        type: templateType,
        id: values.id,
      };

      this.deleteReminder(payload)
        .then(async () => {
          this.actionLoading = false;
          this.pageLoading = false;

          this.loadRemindersByName();

          toasts.success('Reminder Successfully Removed', () => {
            this.showForm = false;
          });
        })
        .catch(() => {
          this.currentReminder = {} as Reminder;
          this.actionLoading = false;
          this.pageLoading = false;
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },
  },
  watch: {
    currentReminder(values) {
      if (values) {
        this.pageLoading = false;
      }
    },
  },
});
