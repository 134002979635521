
import { defineComponent } from 'vue';
import PlaceholderSelect from '@/components/forms/components/PlaceholderSelect.vue';
import toolbarOptions from '@/utils/toolbars';
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import { Reminder } from '@/models/ReminderModel';
import { Reminders as ReminderSchema } from '@/schemas/settings.schemas';

const TINY_MCE_API_KEY = 'm90gcfxe9n3uual8tazg8h2yiaq3pu9fumvhvh94wnrpl91t';
const MAX_LENGTH = 360;

const initValues = {
  send_when: '',
  no_of_days: 0,
  subject: '',
  body: '',
} as {} as unknown as Reminder;

const reminderOptions = [
  {
    value: 'overdue',
    label: 'Overdue',
  },
];

export default defineComponent({
  name: 'sms-reminder-editor-form',
  emits: ['updateReminder', 'createReminder', 'deleteReminder'],
  components: {
    Editor,
    PlaceholderSelect,
  },
  props: {
    currentReminder: {
      type: Object,
      default: () => {
        return {} as Reminder;
      },
    },
    placeholderOptions: {
      type: Object,
    },
    title: {
      type: String,
    },
    actionLoading: {
      type: Boolean,
    },
    formSubmitted: {
      type: Boolean,
    },
  },
  data: () => ({
    api_key: TINY_MCE_API_KEY,
    max_length: MAX_LENGTH,
    formData: initValues,
    subjectInput: {} as typeof HTMLInputElement,
    updateMode: false,
    previewModel: '',
    isEdit: '',
    currentlyActiveField: '',
    config: {
      destroy: true,
      selector: 'textarea',
      auto_focus: 'input',
      menubar: '',
      toolbar: toolbarOptions.join(' '),
      height: '500',
      plugins: 'code',
      contextmenu: 'link image table',
    },
    reminderOptions: reminderOptions,
    pageLoading: true,
  }),
  mounted() {
    this.subjectInput = this.$refs.subjectInput as typeof HTMLInputElement;

    this.initForm();
  },
  methods: {
    initForm() {
      this.formData = {} as unknown as Reminder;

      if (this.currentReminder) {
        this.formData = this.currentReminder as Reminder;
      }
    },

    formSchema() {
      return ReminderSchema;
    },
    handleElementFocus(field) {
      this.currentlyActiveField = field;
    },
    handlePlaceholderSelect(content) {
      if (!this.currentlyActiveField) return;

      const placeholder = `[${content}]`;

      if (this.currentlyActiveField == 'editorInput') {
        getTinymce().activeEditor.insertContent(placeholder);
      }

      if (this.currentlyActiveField == 'subjectInput') {
        this.formData.subject = this.formData?.subject + placeholder;
      }
    },
    limitInput(event) {
      this.formData.body = this.formData.body.substring(0, MAX_LENGTH);

      if (this.formData.body.length >= MAX_LENGTH) {
        event?.preventDefault();
      }
    },
    handleClickDelete() {
      this.$emit('deleteReminder', this.formData);
    },
    updateTemplate() {
      this.$emit('updateReminder', this.formData);
    },
    createTemplate() {
      this.$emit('createReminder', this.formData);
    },
    handleSubmitForm() {
      return this.updateMode ? this.updateTemplate() : this.createTemplate();
    },

    handleNoOfDaysChange(value) {
      if (typeof value === 'undefined') return;

      this.formData.no_of_days = value;
    },
  },
  watch: {
    currentReminder: {
      handler(values) {
        this.updateMode = false;
        if (typeof values.id !== 'undefined') {
          this.updateMode = true;
          this.formData = { ...values } as Reminder;
        }
      },
      immediate: true,
    },
  },
});
